/* eslint-disable no-unused-vars */
import { GET_CURRENT_USERS } from './CurrentUser';
import { GET_FILTERED_TASKS_TABS_PAGINATION } from '../tasksTab/GetFilteredTasksTabPagination';
import Methods from '../../lib/Methods';
import client from '../../config/createApolloClient';
import { SELECTED_USER_FILTERS } from '../../graphql/fragments/user/filters';
import { gql, DPGraphQLMutationHOC } from '../../graphql';

const getRefetchQueries = (paginationProp) =>
{

  const list = [{ query: GET_CURRENT_USERS }];
  if (paginationProp)
  {

    list.push({
      query: GET_FILTERED_TASKS_TABS_PAGINATION,
      variables: paginationProp,
    });

  }
  return list;

};

export const UPDATE_CURRENT_USER_FILTERS = gql`
${SELECTED_USER_FILTERS}
  mutation editFilters(
    $_db: String!
    $type: FilterTypes!
    $filter: PlanningFilterInput
    $updateRecentsOnly: Boolean = false
  ) {
    editFilters(_db: $_db, type: $type, filter: $filter, updateRecentsOnly: $updateRecentsOnly) {
      message
      data {
        selectedFilters{
          ...SelectedFiltersFields
        }
      }
    }
  }
`;

const withUpdateCurrentUserFilters = Component => (props) =>
{

  // const dispatch = useDispatch()
  const { isPublic } = props;
  if (isPublic)
  {

    return <Component {...props} />;

  }
  return (
    <DPGraphQLMutationHOC mutation={UPDATE_CURRENT_USER_FILTERS}>
      {editFilters => (
        <Component
          editFilters={({
            type,
            filter,
            updateRecentsOnly,
            // paginationProp,
            shouldRefetch = 'yes',
            _db,
          }) => editFilters({
            variables: {
              type, filter, updateRecentsOnly, _db,
            },
            refetchQueries: shouldRefetch === 'yes' ? [...getRefetchQueries()] : [],
          })
          }
          {...props}
        />
      )}
    </DPGraphQLMutationHOC>
  );

};

export const editUserFilters = async ({
  type, filter, shouldRefetch = 'yes', updateRecentsOnly, _db,
}) => client.mutate({
  mutation: UPDATE_CURRENT_USER_FILTERS,
  variables: {
    type, filter, updateRecentsOnly, _db,
  },
  refetchQueries: shouldRefetch === 'yes' ? [...getRefetchQueries()] : [],
});

export default withUpdateCurrentUserFilters;
