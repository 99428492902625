import {
  type TypedUseSelectorHook, useDispatch, useSelector,
} from 'react-redux';
import type { AnyAction, Dispatch } from 'redux';
import type { Account, AccountAttributes, User } from '../graphql/generated';
import type { P2ParentEditorState } from './p2ParentEditor/reducers';
import type { P2QueriesState } from './p2Queries/reducers';
import type { P2SearchTextState } from './p2SearchText/reducers';
import type { ParentEditorState } from './parentEditor/reducers';
import type { LoggedInUser } from './redux.types';
import type { InitialSettingState } from './settings/reducers';

export type RootState = {
  user: LoggedInUser,
  account: Account & { _db: string },
  parentEditor: ParentEditorState,
  p2ParentEditor: P2ParentEditorState,
  p2Queries: P2QueriesState,
  p2SearchText: P2SearchTextState,
  settings: typeof InitialSettingState,
  filters: User['filters'],
  accountAttributes: AccountAttributes,
}
export type AppDispatch = Dispatch<AnyAction>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useDPReduxAppSelector: TypedUseSelectorHook<RootState> = useSelector
