import { P2ParentEditorRefType } from '../../apps/p2/components/P2ParentEditor';

export type P2ParentEditorState = {
  reference: P2ParentEditorRefType | null
}
const initialState:P2ParentEditorState = {
  reference: null,
}

export default function userReducer(state = initialState, { type, payload })
{

  switch (type)
  {

    case 'SET_P2_PARENT_EDITOR_DATA':
      return { ...state, ...payload };
    default:
      return state;

  }

}
