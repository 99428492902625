import { Mutation as DPGraphQLMutationHOC, Query as DPGraphQLQueryHOC } from '@apollo/client/react/components/index';
import {
  useLazyQuery, useQuery, useMutation, useSubscription,
  gql,
} from '@apollo/client'


export {
  useLazyQuery,
  useQuery,
  useMutation,
  useSubscription,
  DPGraphQLMutationHOC,
  DPGraphQLQueryHOC,
  gql,

}
