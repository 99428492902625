class DPLayoutUtils
{

  static publicPaths = ['/p/', '/o/', '/pr', '/to', '/as', '/u/']

  static getLayoutName = (pathname: string) =>
  {

    if (pathname === '/')
    {

      return 'public';

    }
    if (/^\/user(?=\/|$)/i.test(pathname) || this.publicPaths.includes(pathname.slice(0, 3)))
    {

      return 'login';

    }
    return 'main';

  }

}
export default DPLayoutUtils;
