import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import filters from './filters/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import account from './account/reducers';
import parentEditor from './parentEditor/reducers';
import p2ParentEditor from './p2ParentEditor/reducers';
import p2Queries from './p2Queries/reducers';
import p2SearchText from './p2SearchText/reducers';
import globalElements from './globalElements/reducers';
import accountAttributes from './account/account.redux'

export const reducersMaker = history => ({
  router: connectRouter(history),
  user,
  menu,
  settings,
  account,
  parentEditor,
  p2ParentEditor,
  p2Queries,
  p2SearchText,
  globalElements,
  filters,
  accountAttributes,
})

export default history => combineReducers(reducersMaker(history))
