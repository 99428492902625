import ParentEditor from '../../components/ParentEditor';

export type ParentEditorState = {
  reference: ParentEditor | null
}
const initialState:ParentEditorState = {
  reference: null,
}

export default function userReducer(state = initialState, { type, payload })
{

  switch (type)
  {

    case 'SET_PARENT_EDITOR_DATA':
      return { ...state, ...payload };
    default:
      return state;

  }

}
