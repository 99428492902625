export interface P2SearchTextState {
  searchText: string | null;
  resetPagination: boolean;
}

const initialState: P2SearchTextState = {
  searchText: localStorage.getItem('searchText') || null,
  resetPagination: false,
}

export default function userReducer(state = initialState, { type, payload })
{

  switch (type)
  {

    case 'SET_P2_SEARCH_TEXT':
      if (payload.searchText !== undefined)
      {

        localStorage.setItem('searchText', payload.searchText);

      }
      return {
        ...state,
        ...payload,
      };
    default:
      return state;

  }

}
