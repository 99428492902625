
export interface P2MeterQueryType {
  refetchAllMeters: () => void
}

export interface P2FacilityQueryType {
  refetchAllFacilities: () => void
}
export interface P2UsersQueryType {
  refetchAllUsers: () => void
}
export type P2QueriesState = {
  meter: P2MeterQueryType | null,
  facility: P2FacilityQueryType | null,
  users: P2UsersQueryType | null,
}
const initialState: P2QueriesState = {
  meter: null,
  facility: null,
  users: null,
}

export default function userReducer(state = initialState, { type, payload })
{

  switch (type)
  {

    case 'SET_P2_METER_QUERY_METHODS':
      return {
        ...state,
        meter: {
          ...state.meter,
          ...payload,
        },
      };
    case 'SET_P2_FACILITY_QUERY_METHODS':
      return {
        ...state,
        facility: {
          ...state.facility,
          ...payload,
        },
      };

    case 'SET_P2_USERS_QUERY_METHODS':
      return {
        ...state,
        users: {
          ...state.users,
          ...payload,
        },
      };
    default:
      return state;

  }

}
