/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { Modal, notification } from 'antd';
// import { FormattedMessage } from 'react-intl';
import { createObjectIdX } from '..';
import { store } from '../..';
import getUserFilter from './filter';

let appUrl = window.location.origin;
if (window.location.hostname.includes('local'))
{

  appUrl = 'http://localhost:4000'

}


class Methods
{

  static getPercent = (tasks = [], hoursLimit, task) =>
  {

    let percentFrom = 0;
    let percentBy = 0;
    if (task)
    {

      percentFrom = Methods.completed(tasks, '')
      percentBy = Methods.totalTasks(tasks)

    }
    else
    {

      const cwh = Methods.completedWorkHours(tasks)
      const ceh = Methods.completedEstHours(tasks)
      const twh = Methods.totalWrkHrsForPercent(tasks)
      const teh = Methods.totalEstHours(tasks)
      if (twh)
      {

        percentFrom = cwh
        percentBy = hoursLimit * 1 || twh

      }
      else
      {

        percentFrom = ceh
        percentBy = hoursLimit * 1 || teh

      }

    }
    const finalPrc = parseInt((
      percentFrom / percentBy
    ) * 100, 10);
    return finalPrc.toString()

  }

  static sortAtoZ = (data, keyName) => (
    (data || []).sort((a, b) => ((a[keyName] || '').toLowerCase() > (b[keyName] || '').toLowerCase() ? 1 : -1))
  )


  static errorHandledMethods = async (
    {
      method,
      key,
      successMessage,
      errorMessage,
    },
    arg1,
    arg2,
    arg3,
  ) =>
  {

    try
    {

      const data = await method(
        arg1,
        arg2,
        arg3,
      );
      if (successMessage)
      {

        notification.success({
          message: successMessage,
        })

      }
      return data?.data[key]

    }
    catch (err)
    {

      notification.error({
        message: errorMessage || err.message,
        ...(err.message ? { description: err.message } : {}),
      })
      return undefined

    }

  }

  static createRandomNumber = (min, max) => Math.floor(Math.random() * (max - min)) + min

  static getStartAndEndDateOfTimePlanner = (dayDate) =>
  {

    const weekStart = moment(dayDate).startOf('week').add(0, 'days')
    return {
      start: moment(weekStart), // moment('2022-01-24T00:00:00.000Z'),
      end: moment(dayDate).endOf('week').add(0, 'days'),
    }

  }

  static getEstimatePercent = (tasks = []) =>
  {

    let percentFrom = 0;
    let percentBy = 0;
    percentFrom = Methods.getTasksEstimation({ tasks, returnCompletedOnly: true })
    percentBy = Methods.getTasksEstimation({ tasks })
    const finalPrc = parseInt((
      percentFrom / percentBy
    ) * 100, 10);
    return finalPrc.toString()

  }


  static isEmptyHTML = (value) =>
  {

    if (value === '<p><br></p>' || value === '<h1><br></h1>' || value === '<h3><br></h3>')
    {

      return true

    }
    return false

  }

  static onLoginResponse = ({ user, dispatch }, loadNewUser = false) =>
  {

    console.log(user, dispatch)
    const localUser = JSON.parse(global.localStorage.getItem('userInfo'))
    // const user = response.data.login;
    const userSetting = {
      expireTime: Date.now() + 50200000,
    };
    global.localStorage.setItem('userInfo', JSON.stringify(user));
    global.localStorage.setItem('userSetting', JSON.stringify(userSetting));
    if (!global.localStorage.getItem('loadedPath')) global.localStorage.setItem('loadedPath', user.loadedPath || '/dashboard')
    Methods.checkAndExpireSession()
    if (user.accounts.length)
    {

      let accountFound = user.accounts.find(i => i._db === user.selectedAccount);
      if (!accountFound)
      {

        accountFound = user?.accounts[0];

      }
      global.sessionStorage.setItem('datbaseName', accountFound._db);
      global.sessionStorage.setItem('datbaseId', accountFound._id);
      const locale = getUserFilter(user.selectedFilters, 'selectedLocale');
      if (locale)
      {

        global.localStorage.setItem('locale', locale);
        global.localStorage.setItem('app.settings.locale', `"${locale}"`);

      }
      if ((user._id !== localUser?._id) || loadNewUser)
      {

        dispatch({
          type: 'SELECTED_ACCOUNT',
          data: accountFound,
        });

      }

    }
    if ((user._id !== localUser?._id) || loadNewUser)
    {

      dispatch({
        type: 'user/LOAD_CURRENT_ACCOUNT',
        payload: user,
      });

    }

  }

  static getTasksEstimation = ({ tasks = [], returnCompletedOnly = false }) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let estimated = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        estimated += (Methods.getTasksEstimation({
          tasks: singleTask.groupTask.tasks, returnCompletedOnly,
        })) * 1 || 0

      }
      else if (singleTask && singleTask.estimateTime)
      {

        if (returnCompletedOnly)
        {

          if (singleTask.isCompleted)
          {

            estimated += singleTask.estimateTime * 1;

          }

        }
        else if (!returnCompletedOnly)
        {

          estimated += singleTask.estimateTime * 1;

        }

      }
      return null

    });
    return estimated

  }

  static getTasksCompletion = (tasks = []) =>
  {

    let percentFrom = 0;
    let percentBy = 0;
    percentFrom = Methods.completed((tasks || []), '')
    percentBy = Methods.totalTasks((tasks || []))
    return `${percentFrom} / ${percentBy}`

  }

  static totalTasks = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let totalTasks = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        totalTasks += Methods.totalTasks(singleTask.groupTask.tasks) * 1

      }
      else
      {

        totalTasks += 1;

      }
      return null

    });
    return totalTasks

  }

  static getProjectsPercentage = (projects) =>
  {

    const data = {
      percent: 0,
      from: 0,
    };
    (projects || []).forEach((project) =>
    {

      const proj = Methods.JsonParStr(project)
      const { tasks } = proj;
      if (tasks)
      {

        const percent = Methods.getEstimatePercent(Methods.JsonParStr(tasks || [])) * 1;
        data.percent += percent || 0;
        data.from += 100;

      }

    })
    const percent = (data.percent / data.from) * 100
    return percent || 0

  }

  static generateWorkedForPlanner = (arg) =>
  {

    const task = Methods.JsonParStr(arg)
    const { worked, name } = task
    const modified = (worked || []).map(({
      _id, date, end, start, userId, hours,
    }) => ({
      _id,
      id: _id,
      date: Methods.createDate(date),
      start: Methods.createDate(start),
      end: Methods.createDate(end),
      hours,
      name,
      userId,
    }))
    return modified

  }

  static totalWrkHours = (tasks = []) =>
  {

    const { length } = (tasks || []);
    if (!length)
    {

      return 0

    }
    let hours = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask)
      {

        if (singleTask.groupTask)
        {

          const tHours = Methods.totalWrkHours(singleTask.groupTask.tasks)
          hours += (tHours > -1) ? (tHours * 1) : 0;

        }
        else if (singleTask.worked)
        {

          const hr = Methods.totalHoursTP(singleTask.worked)
          hours += (hr > -1) ? (hr * 1) : 0;

        }

      }
      return null

    });
    return hours

  }

  static totalHoursTP = (events) =>
  {

    const { length } = (events || []);
    if (!length)
    {

      return '0'

    }
    let hours = 0;
    (events || []).forEach((event) =>
    {

      if (event)
      {

        const diff = (Methods.getHrsDiff(event.start, event.end) * 1)
        hours += (diff || 1)

      }

    });
    return hours

  }

  static getWorkedHours = (events = []) =>
  {

    let hours = 0;
    (events).forEach((event) =>
    {

      hours += event.hours || 0;

    });
    return hours

  }

  static getUserName = (user) =>
  {

    if (user)
    {

      if (user.firstName || user.lastName)
      {

        return `${(user.firstName) || ''} ${(user.lastName) || ''}`

      }
      if (user.email)
      {

        return user.email

      }
      return 'No-Name'

    }
    return '---'

  }

  static convertGroupTaskInCreateMode = (groupTask) =>
  {

    // const groupTask = Methods.JsonParStr(gt)
    if (!groupTask?.tasks?.length) return groupTask
    groupTask.tasks.map((tas) =>
    {

      // convert deliverable obj to id
      if ('deliverable' in tas && tas.deliverable && typeof (tas.deliverable) === 'object')
      {

        tas.deliverable = tas.deliverable._id

      }
      // remove worked
      if (tas.worked)
      {

        delete tas.worked

      }
      // convert comment user obj to id
      if (tas.comments)
      {

        tas.comments.map((comment, i) =>
        {

          if (!comment.userId)
          {

            return tas.comments.splice(i, 1);

          }
          const cId = comment.userId._id
          comment.userId = cId
          return comment

        }).filter(c => c)

      }
      // remove campaign
      if (!tas.campaignType)
      {

        tas.campaignType = undefined

      }
      // convert responsible obj to id
      if (tas.responsible && typeof tas.responsible === 'object')
      {

        const res = tas.responsible?._id;
        tas.responsible = res;

      }
      return tas;

    });
    return groupTask

  }

  static convertGroupTaskDeliverToId = (groupTask) =>
  {

    if (!groupTask?.length) return []
    groupTask.tasks.map((tas) =>
    {

      if ('deliverable' in tas && tas.deliverable && typeof (tas.deliverable) === 'object')
      {

        tas.deliverable = tas.deliverable._id

      }
      return tas;

    });
    return groupTask

  }

  static totalHoursTPMonth = (singleTask, month, year, users) =>
  {

    const events = singleTask.worked;
    const { length } = (events || []);
    if (!length)
    {

      return '0'

    }
    let hours = 0;
    (events || []).map((event) =>
    {

      if (event)
      {

        const mon = moment(Methods.createDate(event.start)).format('MMM')
        const yea = moment(Methods.createDate(event.start)).format('YYYY')
        if (month && year)
        {

          if (users?.length > 0)
          {

            if (users.indexOf(event.userId && event.userId._id) === -1)
            {

              return null

            }

          }
          if (month === mon && yea === year)
          {

            hours += (Methods.getHrsDiff(event.start, event.end) || 1)
            return null

          }
          return null

        }
        hours += (Methods.getHrsDiff(event.start, event.end) || 1)
        return null

      }
      return null

    });
    return hours

  }

  static totalHoursTPSingleDay = (events, date) =>
  {

    const { length } = (events || []);
    if (!length)
    {

      return '0'

    }
    let hours = 0;
    (events || []).map((event) =>
    {

      const eventDate = JSON.stringify(event.date)
      const paramDate = JSON.stringify(date)
      if (event && (eventDate === paramDate))
      {

        const diff = Methods.getHrsDiff(event.start, event.end)
        hours += ((diff * 1) || 1)

      }
      return null

    });
    return hours

  }

  static totalGroupHours = (key, groupTasks = []) =>
  {

    let hours = 0;
    (groupTasks || []).map((t) =>
    {

      let hr = 0;
      if (key === 'worked')
      {

        hr = Methods.totalWorkHours(t.tasks || [])

      }
      else if (key === 'estimateTime')
      {

        hr = Methods.totalEstHours(t.tasks || [])

      }
      hours += (hr * 1) || 0
      return t

    })
    return hours

  }

  static workHoursByRange = ({ project, start, end }) =>
  {

    try
    {

      let worked = []
      const { tasks } = project
      tasks.forEach((task) =>
      {

        task.groupTask.tasks.forEach((t) =>
        {

          worked = [...worked, ...(t.worked || [])]

        })

      })
      worked = worked.map((work) =>
      {

        if (moment(Methods.createDate(work.start)).isBetween(start, end))
        {

          return Methods.getHrsDiff(work.start, work.end)

        }
        return false

      }).filter(w => w).reduce((a, b) => a + b, 0)
      if (worked)
      {

        return ({
          hours: worked || 0,
          project: { name: project.name },
        })

      }
      return false

    }
    catch (err)
    {

      console.log(err.message)
      return false

    }

  }

  static groupWorkedHoursFromRange = (projects = [], { start, end }) =>
  {

    const projectHrs = (projects || [])
      .map(project => Methods.workHoursByRange({ project, start, end })).filter(p => p);
    return (
      {
        projects: projectHrs,
        hours: projectHrs.map(h => h.hours).reduce((a, b) => a + b, 0),
      }

    )

  }

  static completedEstHours = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let completed = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        completed += Methods.completedEstHours(singleTask.groupTask.tasks) * 1

      }
      else if (singleTask && singleTask.isCompleted)
      {

        completed += (singleTask.estimateTime > -1) ? (singleTask.estimateTime * 1) : 0;

      }
      return null

    });
    return completed

  }

  static completedWorkHours = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let completed = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        completed += Methods.completedWorkHours(singleTask.groupTask.tasks) * 1

      }
      else if (singleTask && singleTask.isCompleted)
      {

        const hours = Methods.totalHoursTP(singleTask.worked) || 0
        completed += (hours > -1) ? (hours * 1) : 0;

      }
      return null

    });
    return completed

  }

  static totalEstHours = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return 0

    }
    let time = 0;
    (tasks || []).map((singleTask) =>
    {

      const task = (singleTask || {})
      if (task.groupTask)
      {

        time += (Methods.totalEstHours(task.groupTask.tasks) * 1) || 0

      }
      else
      {

        time += (task.estimateTime > -1) ? (task.estimateTime * 1) : 0;

      }
      return null

    });
    return time

  }

  static totalWorkHours = (tasks = [], month, year, users) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let totalWorkTime = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        totalWorkTime += (Methods.totalWorkHours(
          singleTask.groupTask.tasks, month, year, users,
        ) * 1) || 0

      }
      else
      {

        const hours = (Methods.totalHoursTPMonth(singleTask, month, year, users) * 1) || 0
        totalWorkTime += (hours > -1) ? (hours * 1) : 0;

      }
      return null

    });
    return totalWorkTime

  }

  static totalHoursTPDateRange = (singleTask, startDT, endDT, users) =>
  {

    const events = singleTask.worked || [];
    if (!events.length)
    {

      return 0;

    }
    let hours = 0;
    events.map((event) =>
    {

      if (event)
      {

        if (startDT && endDT)
        {

          if (users?.length > 0)
          {

            if (users.indexOf(event.userId && event.userId._id) === -1)
            {

              return null

            }

          }
          const eventDT = moment(+event.start)
          if (startDT <= eventDT && eventDT <= endDT)
          {

            hours += (Methods.getHrsDiff(event.start, event.end) || 1)
            return null

          }
          return null;

        }
        hours += (Methods.getHrsDiff(event.start, event.end) || 1)
        return null;

      }
      return null;

    })
    return hours;

  }

  static totalWorkHoursDateRange = (tasks, start, end, users) =>
  {

    if (!tasks.length) return 0;
    let totalWorkTime = 0;
    tasks.forEach((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        totalWorkTime += +Methods.totalWorkHoursDateRange(
          singleTask.groupTask.tasks, start, end, users,
        ) || 0;

      }
      else
      {

        const hours = Methods.totalHoursTPDateRange(singleTask, start, end, users) || 0
        totalWorkTime += (hours > -1) ? +hours : 0;

      }

    })
    return totalWorkTime;

  }


  static taskWorkByHours = (singleTask, startDT, endDT, users) =>
  {

    const events = singleTask.worked || [];
    if (!events.length)
    {

      return 0;

    }
    let hours = 0;
    events.map((event) =>
    {

      if (event)
      {

        if (startDT && endDT)
        {

          if (users?.length > 0)
          {

            if (users.indexOf(event.userId && event.userId._id) === -1)
            {

              return null

            }

          }
          const eventDT = moment(+event.date)
          if (startDT <= eventDT && eventDT <= endDT)
          {

            hours += event.hours
            return null

          }
          return null;

        }
        hours += event.hours;
        return null;

      }
      return null;

    })
    return hours;

  }

  static projectWorkedByHours = (tasks, start, end, users) =>
  {

    if (!tasks.length) return 0;
    let totalWorkTime = 0;
    tasks.forEach((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        totalWorkTime += +Methods.projectWorkedByHours(
          singleTask.groupTask.tasks, start, end, users,
        ) || 0;

      }
      else
      {

        const hours = Methods.taskWorkByHours(singleTask, start, end, users) || 0
        totalWorkTime += (hours > -1) ? +hours : 0;

      }

    })
    return totalWorkTime;

  }

  static totalWrkHrsForPercent = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let totalWorkTime = 0;
    tasks.map((singleTask) =>
    {

      const task = (singleTask || {})
      const hours = (Methods.totalHoursTP(task.worked) * 1) || 0
      if (task.groupTask)
      {

        totalWorkTime += (Methods.totalWrkHrsForPercent(task.groupTask.tasks) * 1) || 0

      }
      else if (hours > 0)
      {

        totalWorkTime += (hours * 1)

      }
      else
      {

        totalWorkTime += (task.estimateTime > 0) ? (task.estimateTime * 1) : 0;

      }
      return null

    });
    return totalWorkTime

  }

  static completed = (tasks = []) =>
  {

    if (!tasks)
    {

      tasks = []

    }
    const { length } = tasks;
    if (!length)
    {

      return '0'

    }
    let completed = 0;
    tasks.map((singleTask) =>
    {

      if (singleTask && singleTask.groupTask)
      {

        completed += (Methods.completed(singleTask.groupTask.tasks) * 1) || 0

      }
      else if (singleTask && singleTask.isCompleted)
      {

        completed += 1 * 1;

      }
      return null

    });
    return completed

  }

  static progressBarStrokeColor = (percent, projectCompleted, isOverDue) =>
  {

    const blue = '#1890ff'
    const red = '#ea4234'
    const green = '#6fc51f'
    const due = '#e41f1f'
    const percentGap = percent - 100;
    const toRet = {}
    if (percent > 100)
    {

      toRet[`${100 - percentGap}%`] = green;
      toRet['100%'] = red;

    }
    else if (percent === 100 && projectCompleted)
    {

      toRet['1%'] = green;
      toRet['100%'] = green;

    }
    else if (isOverDue && percent < 100)
    {

      toRet[`${percent}%`] = blue;
      toRet[`${percent + 1}%`] = blue;
      toRet['100%'] = due;

    }
    else
    {

      toRet['1%'] = blue;
      toRet['100%'] = blue;

    }
    return toRet

  }

  static progressBarSettings = (percent) =>
  {

    const blue = '#1890ff'
    const red = '#ea4234'
    const green = '#6fc51f'
    const percentGap = percent - 100;
    const toRet = {}
    if (percent > 100)
    {

      const forPerc = 100 - percentGap
      toRet.percent = 100;
      toRet.successPercent = forPerc;
      toRet.strokeColor = red;

    }
    else if (percent === 100)
    {

      toRet.percent = percent * 1;
      toRet.successPercent = 0
      toRet.strokeColor = green;

    }
    else
    {

      toRet.percent = percent * 1;
      toRet.successPercent = 0
      toRet.strokeColor = blue;

    }
    return toRet

  }

  static filterService = ({ services = [], type }) =>
  {

    let filtered = null;
    if (services)
    {

      filtered = services.filter(service => service.type === type);

    }
    if (filtered)
    {

      return filtered

    }
    return []

  }

  static fileUrlWrapper = (fileName, data) =>
  {

    // http://localhost:4000/files/5e8100a5391fd8318ad43e57.png
    const checkURL = url => (url.match(/\.(jpeg|jpg|gif|png|xlsx)$/) != null)
    if (fileName)
    {

      const size = (checkURL(fileName) && data && data.size) ? `?w=${data.size}` : '';
      if (fileName.substring(0, 4) === 'http')
      {

        fileName = fileName.substr(fileName.lastIndexOf('/') + 1)
        // return `${fileName}${size}`

      }
      const newUrl = `${appUrl}/files/${fileName}${size}`
      return newUrl

    }
    return ''

  }

  static createNew_ids = (param, notToChangeKeys, action = 'createNew') =>
  {

    const notToChange = notToChangeKeys || [];
    const change = (arg, objParentKey) =>
    {

      if (typeof arg === 'object')
      {

        if (!Array.isArray(arg))
        {

          Object.keys(arg || {}).forEach((key) =>
          {

            if (key === '_id' && notToChange.indexOf(objParentKey) === -1)
            {

              if (action === 'createNew')
              {

                const newKey = createObjectIdX()
                arg[key] = newKey

              }
              else if (action === 'delete')
              {

                delete arg._id

              }

            }
            if (typeof arg[key] === 'object')
            {

              change(arg[key], key)

            }

          })

        }
        else if (Array.isArray(arg))
        {

          arg.forEach((item) =>
          {

            if (typeof item === 'object')
            {

              change(item)

            }

          })

        }

      }
      return arg

    }
    const objOrArr = Methods.JsonParStr(param)
    change(objOrArr)
    return objOrArr

  }

  static remove_Ids = (param, notToDeleteKeys) =>
  {

    const notToChange = notToDeleteKeys || [];
    const onRemove = (arg, objParentKey) =>
    {

      if (typeof arg === 'object')
      {

        if (!Array.isArray(arg))
        {

          Object.keys(arg || {}).forEach((key) =>
          {

            if (key === '_id' && notToChange.indexOf(objParentKey) > -1)
            {

              // arg[key] = createObjectIdX()
              delete arg[key]

            }
            if (typeof arg[key] === 'object')
            {

              onRemove(arg[key], key)

            }

          })

        }
        else if (Array.isArray(arg))
        {

          arg.forEach((item) =>
          {

            if (typeof item === 'object')
            {

              onRemove(item)

            }

          })

        }

      }
      return arg

    }
    const objOrArr = Methods.JsonParStr(param)
    onRemove(objOrArr)
    return objOrArr

  }

  static getUrlQueryParam = (name, url) =>
  {

    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));

  }


  static filterCommentsObjSingleTask = (task) =>
  {

    if (task.comments)
    {

      task.comments.map((comment, i) =>
      {

        if (!comment.userId)
        {

          return task.comments.splice(i, 1);

        }
        if (comment && comment.__typename)
        {

          delete comment.__typename

        }
        const cId = comment.userId._id
        comment.userId = cId
        return comment

      }).filter(c => c)

    }
    return task

  }

  static createNewReportWidget = ({
    widget, gaServices, accounts, gadsServices, faServices, liServices,
  }) =>
  {

    console.log('createNewReportWidget');
    const format = 'YYYY-MM-DD HH:mm:ss';
    const initialAccount = accounts.length > 0 ? accounts[0] : null;
    const obj = {
      id: createObjectIdX(),
    };
    const end = moment().format(format);
    const start = moment()
      .add(-30, 'days')
      .format(format);
    if (widget === 'PlanningPrio' || widget === 'Planning')
    {

      obj.title = 'Planning';
      obj.lists = {
        tasks: true,
        analyses: false,
        reports: false,
        meetings: false,
      };
      obj.account = initialAccount;

    }
    else if (widget === 'Assigned to me')
    {

      obj.title = 'Assigned to me by others';
      obj.type = 'aToMe';
      obj.lists = {
        tasks: true,
      };

    }
    else if (widget === 'TopChannels' || widget === 'ChannelsMultiMetrics' || widget === 'Traffic' || widget === 'GAGoals' || widget === 'TrafficMap' || widget === 'PageViews' || widget === 'DeviceSessions' || widget === 'UsersActivity' || widget === 'ConcurrentVisitors' || widget === 'DevicesOverTime' || widget === 'AdsCampaigns' || widget === 'AdsKeywords' || widget === 'AdsClicks' || widget === 'AdsDemographics' || widget === 'EcommerceFunnel' || widget === 'ECommerceBadge' || widget === 'IFrame')
    {

      obj.title = widget;
      if (gaServices && gaServices.length > 0)
      {

        obj.gaService = gaServices && gaServices[0]._id;

      }
      if (gadsServices && gadsServices.length > 0)
      {

        obj.gadsService = gadsServices && gadsServices[0]._id;

      }
      switch (widget)
      {

        case 'TopChannels':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'ChannelsMultiMetrics':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'Traffic':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'GAGoals':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'TrafficMap':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'ConcurrentVisitors':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'PageViews':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'AdsCampaigns':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'AdsKeywords':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'AdsClicks':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'IFrame':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'AdsDemographics':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'DeviceSessions':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'DevicesOverTime':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'UsersActivity':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'EcommerceFunnel':
          obj.dateRange = {
            start,
            end,
          };
          break;
        case 'ECommerceBadge':
          obj.dateRange = {
            start,
            end,
          };
          break;
        default:

      }

    }
    else if (widget === 'FBBadge' || widget === 'FBTopPosts' || widget === 'InstaBadge' || widget === 'InstaTopPosts' || widget === 'FBActiveCampaign' || widget === 'FBLatestPagePosts' || widget === 'FBPosts' || widget === 'FBPostsInteractions' || widget === 'FBPostsReach' || widget === 'FBTargetGroup' || widget === 'InstaTargetGroup' || widget === 'InstaPageReach' || widget === 'FBInstaTrends' || widget === 'InstaLatestPagePosts')
    {

      obj.title = widget;
      if (faServices && faServices.length > 0)
      {

        obj.gaService = faServices && faServices[0]._id;

      }

    }
    else if (['LinkedinLatestPosts', 'LinkedinEngagementAndReach', 'LinkedinFollowersByCountry', 'LinkedInCampaigns', 'LinkedInAds', 'LinkedInCampaignsClicks', 'LinkedInAdsDemographics'].includes(widget))
    {

      obj.title = widget;
      if (liServices && liServices.length > 0)
      {

        obj.liService = liServices && liServices[0]._id;

      }

    }
    else if (widget === 'ContactsCount')
    {

      obj.title = widget;

    }
    else if (widget === 'ECommerceTable')
    {

      obj.title = widget;
      if (gaServices && gaServices.length > 0)
      {

        obj.gaService = gaServices && gaServices[0]._id;

      }

    }
    else if (['MauticBadge'].includes(widget))
    {

      obj.title = widget;

    }
    else if (widget === 'MultiStatsWidget' || widget === 'DPLineChartWidget' || widget === 'DPPieChartWidget' || widget === 'DPTableWidget' || widget === 'DPBarChartWidget')
    {

      obj.title = widget;
      obj.type = widget;

    }
    return obj

  };


  static removeDeliverableObjSingleTask = (task) =>
  {

    if (task.deliverable && typeof task.deliverable === 'object')
    {

      const del = task.deliverable._id;
      task.deliverable = del;

    }
    return task;

  }

  static removeResponsibleObjSingleTask = (task) =>
  {

    if (task.responsible && typeof task.responsible === 'object')
    {

      const res = task.responsible._id;
      task.responsible = res;

    }
    return task;

  }

  static getDateZeroTime = (arg) =>
  {

    let date = arg;
    if (!date)
    {

      date = new Date()

    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;

  };

  static createDate = (date) =>
  {

    if (date > -1)
    {

      return new Date(new Date(date * 1))

    }
    return new Date(date)

  }

  static disabledSpecificDate = (current, states) =>
  {

    const { dateRangeValue: dateRangeState } = states;

    if (!dateRangeState)
    {

      return false;

    }
    const tooLate = dateRangeState[0] && current.diff(dateRangeState[0], 'months') >= 14;
    const tooEarly = dateRangeState[1] && dateRangeState[1].diff(current, 'months') >= 14;
    return !!tooEarly || !!tooLate;

  };


  static getMomentFormat = (type) =>
  {

    const locale = global.localStorage.getItem('locale');
    const isUs = locale === 'en-US';
    const isSw = locale === 'sv-SE';
    if (type === 'date')
    {

      const format = isUs ? 'L' : (isSw && 'YYYY-MM-DD')
      return format

    }
    if (type === 'time')
    {

      const format = isUs ? 'L' : (isSw && 'h:mm a')
      return format

    }
    return type

  }

  static momentFormat = (date, type) =>
  {

    const locale = global.localStorage.getItem('locale');
    const isSw = locale === 'sv-SE';
    const dateFormat = 'YYYY-MM-DD';
    const timeFormat = 'h:mm a';
    if (type === 'date')
    {

      const format = !isSw ? 'L' : (isSw && dateFormat)
      return moment(Methods.createDate(date)).format(format)

    }
    if (type === 'time')
    {

      const format = !isSw ? 'L' : (isSw && timeFormat)
      return moment(Methods.createDate(date)).format(format)

    }
    if (type === 'both')
    {

      const format = !isSw ? 'L' : (isSw && `${dateFormat} ${timeFormat}`)
      return moment(Methods.createDate(date)).format(format)

    }
    return date

  }

  // in case of testing fails get old one back and use this one on ResourceCoverageUtils for meating
  static getHrsDiff = (s, e) =>
  {

    const start = Methods.createDate(s)
    const end = Methods.createDate(e)
    const startDate = moment(new Date(start).getTime())
    const endDate = moment(new Date(end).getTime())
    const minDiff = endDate.diff(startDate, 'minutes')
    const diffToRet = minDiff / 60;
    return diffToRet

  }

  static copyTextToClipboard = (text) =>
  {

    const textArea = global.document.createElement('textarea');
    textArea.id = 'for-text-copy';
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.opacity = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;

    global.document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try
    {

      const successful = global.document.execCommand('copy');
      global.document.getElementById('for-text-copy').remove();
      return (!!successful)

    }
    catch (err)
    {

      return false

    }

  }

  static createTaskUrl = ({
    _db, projectId, groupId, taskId,
  }) => (
    (_db && projectId)
    && (
      `${window.document.location.origin
      }/planning/projects?d=${_db
      }&p=${projectId
      }${(groupId && taskId) ? `&g=${groupId}&t=${taskId}` : ''
      }`
    )
  )

  static createMeetingUrl = ({
    _db, meetingId,
  }) => (
    (_db && meetingId)
    && (
      `${window.document.location.origin
      }/planning/meetings?d=${_db
      }&m=${meetingId
      }`
    )
  )

  static isUserRole = () => store.getState().user.role === 'user';

  static isBetaUser = () => store.getState().user.beta;

  static getLocaleDateFormat = ({ locale = '', enFormat = 'DD-MM-YYYY', swFormat = 'YYYY-MM-DD' }) =>
  {

    const format = locale === 'sv-SE' ? swFormat : enFormat
    return format;

  }; // locale => en-US || sw-SW


  getFormattedText = (id) =>
  {

    const { intl: { formatMessage } } = this.props;
    return formatMessage({ id })

  }

  static warnOnDirty = (showConfirm, onOkClick, setIsDirty) =>
  {

    if (showConfirm)
    {

      Modal.confirm({
        title: this.getFormattedText('lib.method.title'),
        content: this.getFormattedText('lib.method.content'),
        okText: this.getFormattedText('lib.method.okText.title'),
        cancelText: this.getFormattedText('lib.method.cancelText.title'),
        onOk()
        {

          onOkClick();
          setIsDirty(false)

        },
        onCancel()
        {

          setIsDirty(true)

        },
      })

    }
    else
    {

      onOkClick();

    }

  }

  static JsonParStr = arg => JSON.parse(JSON.stringify(arg))

  static getLocalStorageUser = () => JSON.parse(global.localStorage.getItem('userInfo'));

  static getDroppingStyle = (style, snapshot) =>
  {

    if (!snapshot.isDropAnimating)
    {

      return style;

    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: '0.001s',
    }

  }

  static clearLocalStorageExceptSomeKeys(keys = [])
  {

    const { localStorage } = global;

    const keysToRemove = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in localStorage)
    {

      if (keys.indexOf(key) === -1)
      {

        keysToRemove.push(key);

      }

    }
    const keysNotToRemove = ['clear', 'getItem', 'key', 'length', 'removeItem', 'setItem']
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keysToRemove.length; i++)
    {

      if (keysNotToRemove.indexOf(keysToRemove[i]) === -1)
      {

        localStorage.removeItem(keysToRemove[i]);

      }

    }

  }

  static checkAndExpireSession = () =>
  {

    // loadedPath
    try
    {

      const userSetting = JSON.parse(global.localStorage.getItem('userSetting'));// This giving undefined
      const { expireTime } = userSetting;
      const timeLeft = expireTime - Date.now();
      setTimeout(() =>
      {

        this.clearLocalStorageExceptSomeKeys(['userInfo', 'locale', 'app.settings.locale', 'loadedPath'])
        const parentEditorRef = store.getState().parentEditor?.reference
        if (parentEditorRef && parentEditorRef.openLoginModal)
        {

          parentEditorRef.openLoginModal({});

        }

      }, timeLeft)

    }
    catch (error)
    {

      console.log('Error is session expire', error);

    }

  }

  static clearTokenFromStorage = () =>
  {

    const user = JSON.parse(global.localStorage.getItem('userInfo'));
    if (user)
    {

      delete user.token;
      global.localStorage.setItem('userInfo', JSON.stringify(user))

    }

  }

  static hex(value)
  {

    return Math.floor(value).toString(16);

  }

  static ObjectId()
  {

    return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));

  }

  /**
   * Upload multiple files to the server and return the response
   * @param files - Array of files to be uploaded
   * @param uploadFile - Function to upload a single file
   * @param _db - Database instance
   * @returns Array of files with _id, name and url
   * */

  static uploadMultipleFiles = async (files, uploadFile, _db) =>
  {

    try
    {

      const promises = files.map(async (file) =>
      {

        const fileRes = await uploadFile(file, _db);
        const newFile = {
          _id: this.ObjectId(),
          name: file.name,
          url: fileRes.data.uploadFile,
        };
        return newFile;

      });

      const response = await Promise.all(promises);
      return response;

    }
    catch (error)
    {

      console.log(error)
      return [];

    }

  };

}

export default Methods
