import React from 'react';
import { DPLangProvider } from '../lang/DPLangContext/DPLangContext';

export const isLocalhost = (): boolean => location.hostname === 'localhost';
export const isPublic = location.href.includes('/p/');

export const isDebug = (): boolean => {
  const isLocal = isLocalhost();
  const isDebug = location.search.includes('debug') && !location.search.includes('debug=false');

  return isLocal || isDebug;
};

export interface DPProviderProps {
  children: React.ReactNode;
}

export const DPProvider = (props: DPProviderProps) => (
  <DPLangProvider>
    {props.children}
  </DPLangProvider>
);
