import classNames from 'classnames'
import styles from './style.module.scss'
// Add JSDoc to define the type of the props
/**
 * @typedef {Object} LoaderProps
 * @property {boolean} [spinning=true] - Indicates if the loader is spinning.
 * @property {boolean} [fullScreen=false] - Indicates if the loader should be displayed in full screen.
 */
const Loader = ({ spinning = true, fullScreen = false }) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen,
    })}
  />
)

export default Loader
