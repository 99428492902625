import { gql, DPGraphQLQueryHOC } from '../../graphql';

export const GET_FILTERED_TASKS_TABS_PAGINATION = gql`
  query getAllFilterdTasks(
    $page: Int!
    $limit: Int!
    $_db: String
    $userId: String
    $sort: String
    $order: String
    $deleted: Boolean
    $isCompleted: Boolean,
    $onlyDueTask: Boolean
  ) {
    getAllFilterdTasks(
      pagination: { page: $page, limit: $limit }
      _db: $_db
      userId: $userId
      sort: $sort
      order: $order
      deleted: $deleted
      isCompleted: $isCompleted
      onlyDueTask: $onlyDueTask
    ) {
      data {
        _id
        _db
        name
        isCompleted
        projectName
        accountName
        projectDueDate
        publishStartDate
        publishEndDate
        startDate
        dueDate
        dateCreated
        projectId
        groupId
        groupName
        responsible {
          _id
        }
      }
      paginationData {
        totalDocs
      }
    }
  }
`;

const withFilteredTasksTabPagination = Component => (props) =>
{

  const {
    _db, page, limit, deleted, isCompleted, userId, sort, order, isPublic,
  } = props;
  if (isPublic)
  {

    return (
      <Component
        {...props}
      />
    )

  }
  return (
    <DPGraphQLQueryHOC
      query={GET_FILTERED_TASKS_TABS_PAGINATION}
      variables={{
        _db,
        page,
        limit,
        deleted,
        isCompleted,
        userId,
        sort,
        order,
      }}
    >
      {({ loading, data }) => (
        <Component
          loading={loading}
          items={data && data.getAllFilterdTasks}
          {...props}
        />
      )}
    </DPGraphQLQueryHOC>
  );

};

export default withFilteredTasksTabPagination;
