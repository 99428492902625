import store from 'store'
import actions from './actions'

const STORED_SETTINGS = (storedSettings) =>
{

  const settings = {}

  Object.keys(storedSettings).forEach((key) =>
  {

    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]

  })

  return settings

}
const languages = {
  'en-US': 'en-US', en: 'en-US', 'sv-SE': 'sv-SE', sv: 'sv-SE',
}
const selectedBrowserLocale = ['en-US', 'sv-SE', 'sv', 'en'].includes(window.navigator.language) ? languages[window.navigator.language] : 'en-US';

export const InitialSettingState = {
  ...STORED_SETTINGS({
    isMobileView: false,
    isMobileMenuOpen: false,
    isLightTheme: true,
    isSettingsOpen: false,
    isMenuTop: false,
    isMenuCollapsed: false,
    isBorderless: true,
    isSquaredBorders: false,
    isFixedWidth: false,
    isMenuShadow: true,
    locale: selectedBrowserLocale,
  }),
}

export default function userReducer(state = InitialSettingState, action)
{

  switch (action.type)
  {

    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state

  }

}
