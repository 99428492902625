import { Suspense, lazy } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import Error404Page from '@src/views/404'
import Loader from '../../components/Layout/Loader'
import DPIndexLayout from '../common/DPIndexLayout/DPIndexLayout'
import 'antd/dist/antd.css';


const SwishRouter = ({
  history,
}) =>
{


  const routes = [
    {
      path: '/user/login',
      Component: lazy(() => import('@src/views/LoginView')),
      exact: true,
      hidden: false,
    },
    {
      path: '/swish',
      Component: lazy(() => import('./components/Swish/view/Swish.view')),
      exact: true,
      hidden: false,
    },
    {
      path: '/user/password/reset',
      Component: lazy(() => import('@src/views/PasswordResetView')),
      exact: true,
      hidden: false,
    },
    {
      path: '/user/forgot',
      Component: lazy(() => import('@src/views/ForgotView')),
      exact: true,
      hidden: false,
    },


  ]
  return (
    // @ts-ignore
    <ConnectedRouter history={history}>
      <DPIndexLayout defaultPath="/swish">
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/swish" />} />
          {routes.map(({
            path, Component, exact, hidden,
          }) => !hidden && (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={props => (
              <Suspense fallback={<Loader fullScreen={false} />}>
                <Component {...props} />
              </Suspense>
            )}
          />
          ))}
          <Route component={Error404Page} />
        </Switch>
      </DPIndexLayout>
    </ConnectedRouter>
  )

}

export default SwishRouter
