import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

declare type DPHelmetType = {
  path: string;
};

function DPHelmet({ path }: DPHelmetType)
{

  const intl = useIntl();
  const splitedTitle = path?.split('/');
  let title = splitedTitle.length > 0 ? splitedTitle[splitedTitle.length - 1] : 'Digital Platform';
  const translatedTitle = intl.formatMessage({ id: `views.${title}.helmet.title` });
  title = translatedTitle === `views.${title}.helmet.title` ? title : translatedTitle;
  title = title.replace(/(?:^|\s)\S/g, (word: string) => word.toUpperCase());

  return (
    <div>
      {/* @ts-ignore */}
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </div>
  );

}

export default DPHelmet;
